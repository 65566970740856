<script lang="ts">
	import MediaButtons from '../components/MediaButtons.svelte';
	import Section from '../components/Section.svelte';
</script>

<section>
	<div class="relative h-[2px] bg-gradient-to-r from-primary-focus to-secondary" />
</section>

<Section>
	<div
		class="w-full flex flex-col md:flex-row items-center gap-20 justify-between py-20 md:py-12 font-montserrat"
	>
		<img width="152" height="152" class="" src="/kelp_logo.svg" alt="Kelp logo" />
		<MediaButtons />
	</div>

	<div class="w-full text-xs text-center pb-32 font-light opacity-50 md:pb-12">
		<p class="mb-3">Product of KELP DIGITAL OÜ</p>
		<p>Copyright © 2021-present Kelp Digital – All Rights Reserved</p>
	</div>
</Section>

<script>
	import { onMount } from 'svelte';
	export let showOnPx = 200;

	let visible = 'opacity-0 cursor-default';

	function scrollToTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	function setVisibility() {
		visible = window.scrollY > showOnPx ? 'opacity-100' : 'opacity-0 cursor-default';
	}
	onMount(() => setVisibility());
</script>

<svelte:window on:scroll={setVisibility} />

<button
	class="{visible} z-60 transition-opacity duration-500 flex justify-center items-center fixed bottom-8 right-8 h-12 w-12 rounded-full bg-gradient-to-b from-base-200 to-transparent shadow-md active:shadow-pressed hover:to-base-200 hover:shadow-primary shadow-primary/25 backdrop-blur-md"
	aria-label="back to top"
	on:click={scrollToTop}
>
	<img width="22" height="14" src="/icon_up.svg" alt="Back to top" />
</button>
